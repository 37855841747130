import React from "react"
import Layout from '../components/Layout';

const Home: React.FC<{}> = () => {
    return (
	<Layout>
	    <h1 className="text-4xl">Rik Berkelder</h1>
	    <span>Web Developer & Lighting Designer</span>

	    <h2 className="mt-4">Skills</h2>
	    <div className="grid lg:grid-cols-2">
        <div>
          <span className="text-sm text-gray-400 font-bold mt-1 block">Software</span>
          <ul className="list-dash">
            <li>TypeScript, GraphQL</li>
            <li>React, React Native, Angular, Node, Gatsby, Electron</li>
            <li>Sass/SCSS, Tailwind, Bootstrap</li>
            <li>Git, Docker, Heroku, GitLab CI, GitHub Actions</li>
            <li>Linux</li>
		      </ul>
		    </div>
		<div>
		    <span className="text-sm text-gray-400 font-bold mt-3 lg:mt-1 block">Lighting &amp; AV</span>
		    <ul className="list-dash">
			<li>GrandMA2/3, Hog4, Avolites Titan, Obsidian ONYX</li>
			<li>Capture, Vectorworks, WYSIWYG, MA3D</li>
			<li>ATEM, VMix, Arkaos, Resolume, OBS, Datavideo, Resolve</li>
			<li>Timecode, Remote Production, System Integration</li>
      <li>M/X32, A&amp;H Qu-series, Reaper, Ardour/Mixbus, Nuendo</li>
		    </ul>
		</div>
	    </div>

	    <h2 className="mt-4 mb-1">Highlighted Projects/Clients</h2>
	    <ul className="list-dash">
		    <li><a target="_blank" href="http://www.sowulo.nl">Sowulo</a> - Lighting Designer and Operator from 2023</li>
		    <li><a target="_blank" href="https://aitum.tv">Aitum</a> - System integration software for twitch streamers</li>

		    <li><a target="_blank" href="https://twitch.tv/mrgregles">MrGregles</a> - Fully remote, real-time, live lighting workflow & operation</li>
		    <li><a target="_blank" href="http://ecolicht.nl">EcoLicht</a> - Lighting Operator for several events</li>
		    <li><a target="_blank" href="https://rn7.nl">RN7</a> - Custom digital signage for Visual Radio studio</li>

	    </ul>
	</Layout>
    );
};

export default Home;
